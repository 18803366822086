import HtmlText from 'atoms/html-text';
import Image from 'atoms/image';

export default function DescriptionFold({
  title,
  body,
  imageSrc,
}) {
  return (
    <>
      <div className="description-fold">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-xs-12 ">
              {title && <h2>{title}</h2>}
              {body && <HtmlText body={body} />}
            </div>
            <div className="col-lg-4 col-md-4 col-xs-12">
              <Image className="img-round" src={imageSrc} />
            </div>  
          </div>
        </div>
      </div>

      <style jsx>{`
        .description-fold {
          background: #FFFFFF;
        }
      `}</style>
    </>
  )
}
