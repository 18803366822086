import Image from 'atoms/image'

export default function BoxHorizontal({ 
    imageSrc,
    boxTitle,
    children,
    titleAs = 'h4',
}) {
    return (
      <div className="row align-items-center box">
        <div className="col-xs-12 col-lg-4 text-center">
          {imageSrc && <Image src={imageSrc} fullWidth />}
          {boxTitle && titleAs === 'h3' && <h3><strong>{boxTitle}</strong></h3>}
          {boxTitle && titleAs === 'h4' && <h4><strong>{boxTitle}</strong></h4>}
        </div>
        <div className="col-xs-12 col-lg-8 text-center">
          {children}
        </div>
      </div>
    )
}
