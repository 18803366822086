import Button from 'atoms/button'

export default function ReadMoreFold({
  posts,
}) {
  return (
    <>
      <div className="read-more-fold">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <h2 className="text-white">Lees meer</h2>
            </div>
            {posts && posts
              .filter(post => post.id && post.slug && post.title?.rendered)
              .map(post => {
              return (
                <div key={post.id} className="col-6 col-md-4 col-lg-2 text-center mx-3">
                  <Button type="secondary" link={(new URL(post.link)).pathname}>
                    {post.title.rendered}
                  </Button>
                </div>
              );
            })}

            <div className="col-6 col-md-4 col-lg-2 text-center mx-3">
              <Button type="secondary" target="_blank" link="https://pijnvrij.fysiotherapie4all.nl/de-oplossing-voor-pijn/">
                Bestel het boek
              </Button>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .read-more-fold {
          background: #102E4A;
          padding: 50px 0;
          position: relative;
          z-index: 1;
        }
      `}</style>
    </>
  )
}