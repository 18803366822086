import HtmlText from 'atoms/html-text';
import BoxHorizontal from 'molecules/box-horizontal';

export default function ComplaintTypes({
  title,
  subtitle,
  types,
}) {
  return (
    <>
      <div className="klachten-fold">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xs-12 col-lg-6 pt-5">
              <h2 className="text-center">{title}</h2>
              <HtmlText body={subtitle} />
            </div>
          </div>
          {types.map(type => {
            return (
              <div key={`${type.title}-row`} className="row justify-content-center">
                <div className="col-xs-12 col-lg-8">
                  <BoxHorizontal
                    imageSrc={type.imageSrc}
                    boxTitle={type.title}
                    titleAs="h3"
                  >
                    <HtmlText body={type.body} />
                  </BoxHorizontal>
                </div>
              </div>    
            )
          })}
        </div>
      </div>

      <style jsx>{`
        .klachten-fold {
          background-color: #F5F5F5;
          //background: url('/img/backgrounds/klachten-fold-four.svg');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top center;
          //padding: 0 0 50px 0;
          //margin-top: -120px;
        }
      `}</style>
    </>
  )
}
