import Image from 'atoms/image'
import Button from 'atoms/button'

export default function BoxVertical({ 
    imageSrc,
    children,
    buttonType,
    buttonLink,
    Label,
}) {
    return (
      <div className="box vertical">
        {imageSrc && <Image src={imageSrc} />}
        {children}
        <div className="box-button-wrapper">
          <Button type={buttonType} link={buttonLink}>
            {Label}
          </Button>
        </div>
      </div>
    )
}
