import HtmlText from 'atoms/html-text'
import BoxVertical from 'molecules/box-vertical'
import BoxPlain from 'molecules/box-plain'
import { Col, Container, Row } from 'react-bootstrap';

export default function RelatedPostsSection({
  title,
  subtitle,
  posts,
}) {
  return (
    <>
      <Container>
        <Row>
          <Col className="col-xs-12 col-md-8 offset-md-2 text-center">
            <h2>{title}</h2>
            {subtitle && <BoxPlain>
              <div dangerouslySetInnerHTML={{ __html: subtitle }} />
            </BoxPlain>}
          </Col>
        </Row>
        <Row className="justify-content-center">
          {posts && posts.map(post => {
            return (
              <Col 
                key={post.id}
                className={"col-xs-12 col-md-6 col-lg-4 text-center mb-4"}
              >
                <BoxVertical
                  imageSrc={post._links['wp:featuredmedia'][0].href}
                  buttonType="tertiary"
                  buttonLink={(new URL(post.link)).pathname}
                  Label="Lees meer"
                >
                  <h3><HtmlText body={post.title.rendered} /></h3>
                  <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                </BoxVertical>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  )
}
