import Head from 'next/head'
import Hero from 'components/folds/common/hero-fold'

export default function LoadingPage() {
  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <main>
        <Hero title="Bezig met laden..."/>
      </main>
    </>
  )
}
