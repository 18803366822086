import Button from 'atoms/button'
import HtmlText from 'atoms/html-text';
import TextImageRow from 'components/text-image-row';

export default function PrognosisFold({
  title,
  mainImage,
  link,
  prognosis,
  backgroundImageSrc,
  dark,
  className,
}) {
  return (
    <>
      <div className={`prognosis-fold ${className != "undefined" ? className : ''}`}>
        <div className="container">
          <TextImageRow
            imageSrc={mainImage.src}
            imgClassName={mainImage.className}
            boxShadow={mainImage.boxShadow}
          >
            <h2 className={dark ? "text-white" : ''}><HtmlText body={title}/></h2>
            <h3 className={dark ? "text-white" : ''}>{prognosis.cause.title}</h3>
            <HtmlText className={dark ? "text-white" : ''} body={prognosis.cause.description} />
            <h3 className={dark ? "text-white" : ''}>{prognosis.symptoms.title}</h3>
            <HtmlText className={dark ? "text-white" : ''} body={prognosis.symptoms.description} />
            <h3 className={dark ? "text-white" : ''}>{prognosis.treatment.title}</h3>
            <HtmlText className={dark ? "text-white" : ''} body={prognosis.treatment.description} />
            <Button type={dark ? "secondary" : "primary"} link={link}>Bekijk in detail</Button>
          </TextImageRow>
        </div>
      </div>

      <style jsx>{`
        .prognosis-fold {
          ${backgroundImageSrc ? `background: url(${backgroundImageSrc})` : ``};
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          padding: ${backgroundImageSrc ? '140px 0 100px 0' : '0 0 20px 0'};
          margin-top: 0px;
        }

        .prognosis-fold h3 {
          margin-top: 30px;
        }

        @media screen and (max-width: 991px) {
          .prognosis-fold {
            //padding: 150px 0 80px 0;
            padding: 0;
            margin-top: 0;
            //margin-top: -40px;
          }
        }

        @media screen and (max-width: 767px) {
          .klachten-fold {
            margin: -60px 0 -120px 0;
          }
        }
      `}</style>
    </>
  )
}
