import { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';

export default function SingleVideoFold({
  url,
  title = '',
  subtitle,
  className = '',
}) {
  const [hasWindow, setHasWindow] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setHasWindow(true);
    }
  }, []);
  
  return (
    <>
      <div className="single-video-fold">
        <Container>
          <Row className="justify-content-center pt-4 pb-0">
            {title && <Col className="col-12 text-center mb-4">
                <h2>{ title }</h2>
              </Col>}

            <Col className={`${className} col-12`}>
              <div className="player-container">
                <div className="player-wrapper">
                  { hasWindow && <ReactPlayer
                    url={url}
                    width="100%"
                    height="100%"
                    controls
                  /> }
                </div>
              </div>
              {subtitle && <div
                className="mt-4 mx-5 text-center"
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />}
            </Col>
          </Row>
        </Container>
      </div>

      <style jsx>{`
        .single-video-fold {
          background: #FFFFFF;
        }

        .player-container {
          padding-top: 56.25%;
          position: relative;
        }

        .player-wrapper {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
      `}</style>
    </>
  )
}
