import HtmlText from 'atoms/html-text';
import Image from 'atoms/image';

export default function KlachtenCausesSymptomsTreatment({
  title,
  causesData,
  symptomsData,
  treatmentData,
}) {
  return (
    <>
      <div className="klachten-fold">
        <div className="triangular-background">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
            <g fill="#F5F5F5">
              <polygon points="0,5 0,100 100,100 100,75" />
            </g>
            <g fill="#F5F5F5">
              <polygon points="0,95 100,25 100,100 0,100" />
            </g>
          </svg>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-10 offset-md-1 col-xl-5 offset-xl-7 text-center">
              <h2>{title}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="wrapper text-center">
                <Image className="img-thumbnail" src={causesData.imageSrc} />
                <h3>{causesData.title}</h3>
                <HtmlText body={causesData.body} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="wrapper smaller text-center">
                <Image className="img-thumbnail" src={symptomsData.imageSrc} />
                <h3>{symptomsData.title}</h3>
                <HtmlText body={symptomsData.body} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="wrapper bigger text-center">
                <Image className="img-thumbnail" src={treatmentData.imageSrc} />
                <h3 className="text-white">{treatmentData.title}</h3>
                <HtmlText className="text-white" body={treatmentData.body} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .klachten-fold {
          background: #F5F5F5;
          padding-top: 40px;
          position: relative;
        }

        .triangular-background {
          position: absolute;
          z-index: 0;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin-bottom: -1px; /* Due to an issue in Chrome's  */
        }

        .triangular-background svg {
          display: block;
          width: 100%;
          height: 100%;
        }

        ul {
          padding-left: 80px;
        }

        .wrapper {
          background: #ffffff;
          border-radius: 50%;
          width: 645px;
          height: 645px;
          padding: 30px 50px;
          position: relative;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }

        .wrapper.bigger {
          width: 700px;
          height: 700px;
          background: #5887FF;
        }

        .wrapper.smaller {
          width: 615px;
          height: 615px;
          background: #E5E5E5;
        }

        @media screen and (min-width: 768px) and (max-width: 1199px) {
          .wrapper {
            margin: 0 auto;
          }
        }

        @media screen and (min-width: 1200px) {
          .wrapper {
            margin-top: -150px;
          }

          .wrapper.smaller {
            margin-left: auto;
            margin-top: -170px;
          }

          .wrapper.bigger {
            margin-top: -200px;
          }

          h2 {
            margin-top: 140px;
            margin-bottom: -170px;
          }
        }

        @media screen and (max-width: 767px) {
          ul {
            padding-left: 20px;
          }

          .wrapper {
            border-radius: 20px;
            width: 100%;
            height: auto;
            padding: 20px;
            margin: 0 auto;
          }

          .wrapper.bigger,
          .wrapper.smaller {
            width: 100%;
            height: auto;
          }
        }
      `}</style>
    </>
  )
}
