import React from 'react'
import Head from 'next/head'
import Hero from 'components/folds/common/hero-fold'
import Video from 'components/folds/common/single-video-fold'
import LoadingPage from 'components/folds/common/loading'
import Types from 'components/folds/klachten-type'
import PainCause from 'components/folds/klachten-pain-cause'
import CausesSymptomsTreatment from 'components/folds/klachten-causes-symptoms-treatment'
import ReadMore from 'components/folds/common/read-more-fold'
import DescriptionFold from 'components/folds/common/description-fold'
import { fetchAllPostsOfType, fetchPostIdsOfType, fetchFullPosts } from 'lib/wp-fetcher'
import PrognosisFold from 'components/folds/common/prognosis-fold'
import { useRouter } from 'next/router'
import { cachedFetch } from 'lib/cached-fetch'
import RelatedPostsSection from 'components/folds/common/related-posts-section'
import { RankMathData } from 'lib/rank-math'
import parse from 'html-react-parser'

export default function ComplaintPage({ complaint, rankMathHead }) {
  const router = useRouter();

  if(router.isFallback) {
    return <LoadingPage />
  }

  const {
    acf: {
      header,
      description,
      process,
      types,
      causes,
      triggerpoints,
    },
    relevantCauses,
    readMore,
    mainVideo,
  } = complaint;

  const readMoreSettings = [
    {
      mainImage: {
        className: "img-plain",
        boxShadow: false,
      },
      isDark: false,
      backgroundImageSrc: false,
    },
    {
      // isDark: true,
      // backgroundImageSrc: '/img/backgrounds/klachten-fold-eight.svg',
      isDark: false,
      backgroundImageSrc: false,
      className: "bg-grey-2",
    },
    {
      mainImage: {
        className: "img-plain",
        boxShadow: false,
      },
      isDark: false,
      backgroundImageSrc: false,
    },
    {
      // isDark: true,
      // backgroundImageSrc: '/img/backgrounds/klachten-fold-nine.svg',
      isDark: false,
      backgroundImageSrc: false,
      className: "bg-grey-2",
    },
  ];

  return (
    <div>
      <Head>
        {complaint?.link ? (
          parse(rankMathHead)
        ) : (
          <title>Fysiotherapie4all - {complaint?.title?.rendered}</title>
        )}
      </Head>
      <div>
        <main>

          {header && <Hero
            title={header.title}
            subtitle={header.subtitle}
            webinarLink={header.webinarLink}
          />}
          {description && <DescriptionFold
            title={description.title}
            body={description.body}
            imageSrc={description.image}
          />}
          {mainVideo?.acf?.url && <Video
            url={mainVideo.acf.url}
          />}
          {types && <Types
            title={types.title}
            subtitle={types.description}
            types={types.type.map(type => ({
              ...type,
              body: type.description,
              imageSrc: type.image,
            }))}
          />}
          {causes && relevantCauses && <PainCause
            title={causes.title}
            causes={relevantCauses.map(cause => ({
              ...cause,
              title: cause.title?.rendered,
              excerpt: cause.excerpt?.rendered,
              imageSrc: cause.acf?.related_data?.icon,
            }))}
          />}
          {triggerpoints && <RelatedPostsSection
            title={triggerpoints.title}
            subtitle={triggerpoints.subtitle}
            posts={triggerpoints.posts}
          />}
          {process && <CausesSymptomsTreatment
            title={process.title}
            causesData={{
              imageSrc: process.cause.image,
              title: process.cause.title,
              body: process.cause.description,
            }}
            symptomsData={{
              imageSrc: process.symptoms.image,
              title: process.symptoms.title,
              body: process.symptoms.description,
            }}
            treatmentData={{
              imageSrc: process.treatment.image,
              title: process.treatment.title,
              body: process.treatment.description,
            }}
          />}
          {readMore && <ReadMore posts={readMore} />}
          { /* relevantCauses && relevantCauses
            .filter(post => post.title && post.acf)
            .map((post, index) => {
            const readMoreIndex = index < readMoreSettings.length ? index : index % readMoreSettings.length
            const rowSettings = readMoreSettings[readMoreIndex];

            return (
              <PrognosisFold
                key={post.id}
                title={post.title?.rendered}
                mainImage={{
                  src: post.acf?.prognosis?.image,
                  className: rowSettings.mainImage?.className,
                  boxShadow: rowSettings.mainImage?.boxShadow,
                }}
                className={rowSettings.className}
                dark={rowSettings.isDark}
                link={(new URL(post.link)).pathname}
                backgroundImageSrc={rowSettings.backgroundImageSrc}
                prognosis={{
                  cause: {
                    title: post.acf?.prognosis?.cause?.title,
                    description: post.acf?.prognosis?.cause?.description,
                  },
                  symptoms: {
                    title: post.acf?.prognosis?.symptoms?.title,
                    description: post.acf?.prognosis?.symptoms?.description,
                  },
                  treatment: {
                    title: post.acf?.prognosis?.treatment?.title,
                    description: post.acf?.prognosis?.treatment?.description,
                  },
                }}
              />
            );
          }) */ }
        </main>
      </div>
    </div>
  )
}

export async function getStaticPaths() {
  const paths = []
  
  const posts = await fetchAllPostsOfType('complaints')

  posts
    .map((post) => {
      return {
        params: { 
          id: post.id.toString(),
          articleType: post.slug
        },
      }
    })
    .forEach(post => paths.push(post))

  return { paths, fallback: true }
}

export async function getStaticProps({ params }) {
  const posts = await cachedFetch(`${process.env.NEXT_PUBLIC_API_URL}/wp-json/wp/v2/complaints/?slug=${params.articleType}`)
  const complaint = posts[0]

  if (!complaint) {
    return {
      notFound: true,
      revalidate: parseInt(process.env.REVALIDATION_TIME_SECONDS),
    }
  }

  if (complaint.acf.main_video) {
    complaint.mainVideo = (await fetchPostIdsOfType(complaint.acf.main_video, 'videos'))[0]
  }

  complaint.relevantCauses = await fetchFullPosts(complaint.acf.causes?.relevant_causes);
  complaint.readMore = await fetchFullPosts(complaint.acf.read_more?.read_more_causes);

  if (complaint.acf.triggerpoints?.posts) {
    complaint.acf.triggerpoints.posts = await fetchPostIdsOfType(complaint.acf.triggerpoints?.posts, 'triggerpoint');
  }

  if(complaint.acf?.types) {
    complaint.acf.types.type = complaint.acf.types.type || [];
  }

  return {
    props: { 
      complaint,
      rankMathHead: await RankMathData(complaint?.link),
    },
    revalidate: parseInt(process.env.REVALIDATION_TIME_SECONDS),
  }
}
