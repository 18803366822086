import HtmlText from 'atoms/html-text'
import BoxVertical from 'molecules/box-vertical'

export default function KlachtenPainCause({
  title,
  causes,
}) {
  return (
    <>
      <div className="klachten-fold">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-8 offset-md-2 text-center">
              <h2>{title}</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            {causes && causes.map(cause => {
              return (
                <div 
                  key={cause.id}
                  className={"col-xs-12 col-md-6 col-lg-4 text-center box-wrapper"}
                >
                  <BoxVertical
                    imageSrc={cause.imageSrc}
                    buttonType="tertiary"
                    buttonLink={(new URL(cause.link)).pathname}
                    Label="Lees meer"
                  >
                    <h3><HtmlText body={cause.title} /></h3>
                    <div dangerouslySetInnerHTML={{ __html: cause.excerpt }} />
                  </BoxVertical>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <style jsx>{`
        .klachten-fold {
          background-color: #fff;
          //background: url('/img/backgrounds/klachten-fold-six.svg');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: bottom center;
        }

        .box-wrapper {
          margin-bottom: 30px;
        }

        @media screen and (max-width: 991px) {
          .klachten-fold {
            background-size: cover;
          }

        }
      `}</style>
    </>
  )
}
