export default function BoxHorizontal({ 
    children,
}) {
    return (
      <div className="row align-items-center box">
        <div className="col-12">
          {children}
        </div>
      </div>
    )
}
